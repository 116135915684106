.dropzone {
  display: block;
  width: 80%;
  margin: 0 auto 25px auto;
  padding: 60px;
  color: rgba(128, 137, 150, 0.7);
  border: 2px dashed rgba(128, 137, 150, 0.3);
  text-align: center;
  -webkit-transition: box-shadow 0.3s, border-color 0.3s;
  -moz-transition: box-shadow 0.3s, border-color 0.3s;
  transition: box-shadow 0.3s, border-color 0.3s;
}
.dropzone:hover {
  border-color: rgba(128, 137, 150, 0.8);
}
.dropzone .drag-drop-icon {
  font-size: 48px;
  margin-bottom: 20px;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
}
.dropzone h3 {
  margin: 0;
  font-size: 18px;
}
.dropzone .drag-drop-btn {
  margin-top: 2rem;
  display: inline-block;
  padding: 8px 14px;
  outline: none;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  white-space: nowrap;
  font-size: 12px;
  font-weight: bold;
  color: #8d9496;
  border-radius: 3px;
  border: 1px solid #c6c6c6;
  vertical-align: middle;
  *background-color: #fff;
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
.drag-and-drop-wrap .drag-and-drop-file .jFiler-input-dragDrop {
  background-color: transparent;
  text-align: center;
}
.drag-and-drop-wrap
  .drag-and-drop-file
  .jFiler-input-dragDrop
  .jFiler-input-inner
  .jFiler-input-text
  .or-divider {
  display: none;
}
.drag-and-drop-wrap
  .drag-and-drop-file
  .jFiler-input-dragDrop
  .jFiler-input-inner
  .jFiler-input-choose-btn {
  margin-top: 20px;
}

.wrapper-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.editor-class {
  padding: 1rem;
  border: 1px solid #ccc;
}

.toolbar-class {
  border: 1px solid #ccc;

}

@media screen and (max-width: 600px) {
  .dropzone {
    width: 50%;
    overflow-x: hidden;
  }
}